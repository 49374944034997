.reveal {
  box-shadow: $reveal-box-shadow;
  //color: $white;
  @include disable-mouse-outline;
  label{
    color: $medium-gray;
  }

  .button-group {
    margin-top: rem-calc(34);
  }
  .modal-title {
    margin-top: 0;
  }
  .close-button{
    float: right;
    cursor: pointer;
    @include disable-mouse-outline;
  }
  &.ice-bg{
    background-color: get-color('ice');
    color: $dark-gray;
    label{
      color: $medium-gray;
    }
    a{
      @extend a;
    }
  }
}
.screen-overlay{
  display: none;
  position: fixed;
  top: 174px;
  left: 0;
  background: $reveal-overlay-background;
  z-index: 50;
  height: 100vh;
  width: 100vw;
}
