ul.pagination {
    a {
        border-bottom: 0;
        &:hover {
            color: $primary-color;
        }
    }
    .button-link {
        cursor: pointer;
        color: $medium-gray;
        &:disabled {
            cursor: not-allowed;
            color: $ui-light-gray;
        }
    }
    .pagination-previous, .pagination-next {
        a {
            color: lighten($medium-gray, 15%);
        }
    }
    .pagination-previous, .pagination-previous.disabled {
        margin-right: 1rem;
        font-weight: 600;
    }
    .pagination-next, .pagination-next.disabled {
        margin-left: 1rem;
        font-weight: 600;
    }
    .ellipsis::after {
        padding: 0;
        margin-left: -.5rem;
    }
    .pagination-previous.disabled, .pagination-next.disabled {
        color: darken($light-gray, 5%);
    }
    li {
        word-spacing: initial;
        border: 0;
        border-radius: 0;
        font-size: rem-calc(16);
        text-align: center;
        & > span {
            display: inline-block;
            padding: 0;
        }
        &:before {
            margin: 0;
        }
        &.page-label {
            margin-right: 0.5rem;
        }
        &.current {
            font-weight: 700;
            border-bottom: 3px solid get-color(secondary);
            a {
                color: $dark-gray;
            }
        }
    }
    &.pg-mobile {
        li {
            & > span {
                margin: 0;
                padding: $pagination-item-padding;
            }
        }
    }
}
