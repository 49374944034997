.aside-wrapper {
    background-color: $off-white;
    padding: 1rem;
    margin-bottom: 2rem;
}
@media screen and #{breakpoint(medium)} {
    .aside-wrapper {
        &.sub-pref {
            padding: 1rem 2rem;
        }
    }
}
@media screen and #{breakpoint(large)} {
    .aside-wrapper{
        margin-left: 2rem;
        margin-bottom: 0;
    }
}

.help-box {
    margin-top: 2rem;
    border: $card-border;
    border-radius: $global-radius;
}

.help-card {
    text-align: center;
    padding: 1.5rem 0;
    &.text-left {
        text-align: left;
    }
    &-title {
        font-family: $sec-font;
        font-weight: 700;
        font-size: rem-calc(18);
        margin: 0 0 1rem;
    }
    &-icons {
        display: block;
        margin: 0 auto .5rem;
        color: $ui-light-gray;
        font-size: rem-calc(30);
    }
    &-item {
        margin: 1rem 0;
        .default-icons {
            font-size: rem-calc(22);
        }
    }
}
