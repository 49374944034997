$cas-tabs-box-shadow: 0px 3px 0px 0px $white, 0px 0 2px 0px rgba(get-color('midnight'), 0.3);
$cas-vertical-tabs-box-shadow: 4px 0 0 0 $white, 1px 0px 2px 0px rgba(get-color('midnight'), 0.3);

$cas-tabs-first-child-box-shadow: 0px 3px 0px 0px $white, 1px 0 2px 0px rgba(get-color('midnight'), 0.3);
$cas-tabs-last-child-box-shadow: 0px 3px 0px 0px $white, -1px 0 2px 0px rgba(get-color('midnight'), 0.3);

$cas-vertical-tabs-first-child-box-shadow: 2px 0px 0px 0px $white, 0px 1px 2px 0px rgba(get-color('midnight'), 0.3);
$cas-vertical-tabs-last-child-box-shadow: 4px 0 0px 0px $white, 0px -1px 2px 0px rgba(get-color('midnight'), 0.3);
$cas-vertical-tabs-padding: rem-calc(15.5) 2rem;

$cas-tabs-content-box-shadow: 0 1px 2px 0px rgba(get-color('midnight'), 0.1);
$cas-tabs-border-radius: $global-radius;

.tabs-icon {
  width: 17px;
  height: 17px;
  fill: $ui-gray;
  vertical-align: top;
  @include breakpoint(xlarge) {
    margin-right: rem-calc(10);
  }
}

.tabs-wrapper {
  display: inline-block;
  border-radius: $cas-tabs-border-radius;
    border-top: 3px solid transparent;
  &.vertical {
    display: block;
    border-radius: $cas-tabs-border-radius;
    box-shadow: $cas-tabs-content-box-shadow;
    border: 1px solid $light-gray;
    border-top: 0;
    border-left: 0;
  }
}

.tabs {
  border-bottom: 0;
  border-top-left-radius: $cas-tabs-border-radius;
  border-top-right-radius: $cas-tabs-border-radius;
  &.vertical {
    border-radius: 0;
    border-top-left-radius: $cas-tabs-border-radius;
    border-bottom-left-radius: $cas-tabs-border-radius;
  }
  li {
    margin-top: 0;
    margin-bottom: 0;
    &:first-child {
      a {
        border-top-left-radius: $cas-tabs-border-radius;
      }
    }
  }
}

.tabs-title {
  &:before {
    display: none;
  }
  a {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    position: relative;
    @include disable-mouse-outline;
    &:hover {
      border-top: 3px solid get-color('cyan');
      box-shadow: $cas-tabs-box-shadow;
    }
  }
  &.is-active {
    a {
      color: get-color('cyan');
      font-weight: 700;
      border-top: 3px solid get-color('cyan');
      box-shadow: $cas-tabs-box-shadow;
      @include disable-mouse-outline;
    }
    svg {
      fill: get-color('cyan');
    }
    &:first-child {
      a {
        box-shadow: $cas-tabs-first-child-box-shadow;
      }
    }
    &:last-child:not(:only-of-type) {
      a {
        box-shadow: $cas-tabs-last-child-box-shadow;
      }
    }
  }
  &:first-child {
    border-top-left-radius: $cas-tabs-border-radius;
    a {
      &:hover {
        box-shadow: $cas-tabs-first-child-box-shadow;
      }
    }
  }
  &:last-child:not(:only-of-type) {
    a {
      &:hover {
        box-shadow: $cas-tabs-last-child-box-shadow;
      }
    }
  }
}

.tabs.vertical {
  .tabs-title {
    &:before {
      display: none;
    }
    a {
      border: 0;
      padding: $cas-vertical-tabs-padding;
      border-left: 3px solid transparent;
      &:hover {
        border-top: 0;
        border-left: 3px solid get-color('cyan');
        box-shadow: $cas-vertical-tabs-box-shadow;
      }
    }
    &.is-active {
      a {
        border-top: 0;
        border-left: 3px solid get-color('cyan');
        box-shadow: $cas-vertical-tabs-box-shadow;
        @include disable-mouse-outline;
      }
      &:first-child {
        a {
          box-shadow: $cas-vertical-tabs-first-child-box-shadow;
        }
      }
    }
    &:first-child {
      border-top-left-radius: $cas-tabs-border-radius;
      a {
        &:hover {
          box-shadow: $cas-vertical-tabs-first-child-box-shadow;
        }
      }
    }
    &:last-child:not(:only-of-type) {
      border-radius: 0;
      a {
        border-radius: 0;
        &:hover {
          box-shadow: $cas-vertical-tabs-last-child-box-shadow;
        }
      }
    }
  }
}

.tabs-content {
  box-shadow: $cas-tabs-content-box-shadow;
  border-bottom-left-radius: $cas-tabs-border-radius;
  border-bottom-right-radius: $cas-tabs-border-radius;
  &.vertical {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-top: 1px solid $light-gray;
    border-top-right-radius: $cas-tabs-border-radius;
  }
}

