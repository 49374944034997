
.account-form-wrapper {
    padding: 3rem 2rem;
    margin-bottom: 2rem;
    background-color: #efefef;
    &.forgot {
        background: url('../images/assets/customer/forgot-pass-bg.png') no-repeat bottom right #efefef;
    }
    &.reset {
         background: url('../images/assets/customer/reset-pass-bg.png') no-repeat bottom right #efefef;
        input {
            margin-bottom: 1rem;
        }
    }
    .form-subtitle {
        font-size: rem-calc(20);
        margin: 0 0 1rem;
    }
    .form-container {
        margin-bottom: 3rem;
        border-bottom: $global-border;
        padding-bottom: 3rem;
    }
}

.other-account-link {
    margin-top: 2rem;
}

.account-form-wrapper {
    .sub {
        &-disclaimer {
            margin-top: -.5rem;
        }
    }
}

.sub {
    &-me {
        margin: 1rem 0;
        label {
            font-weight: 600;
        }
    }
    &-disclaimer {
        font-style: italic;
        display: inline-block;
    }
}
.track-order-form {
    .checkbox-wrapper {
        margin-top: 2rem;
    }
}
.modal-form {
    .sub {
        &-disclaimer {
            margin-top: -1.5rem;
        }
    }
    .res-message {
        display: none;
    }
    @include callout(success);
    @include callout(alert);
}


@media screen and #{breakpoint(medium)} {
    .account-form-wrapper {
        margin-top: 2rem;
        .sub-me {
            margin-bottom: 1rem;
        }
        .form-subtitle {
            margin-bottom: 2rem;
        }
        .form-container {
            border-bottom: 0;
            margin: 0 1rem 0 0;
            padding: 0 2rem 0 0;
            border-right: $global-border;
        }
    }
    .sub-me {
        margin-bottom: 0.5rem;
    }
}

@media screen and #{breakpoint(large)} {
    .account-form-wrapper {
        .sub-me {
            margin-bottom: 0;
            > label {
                margin-bottom: 0.5rem;
            }
        }
        .form-container {
            margin-right: 2rem;
            padding-right: 4rem;
        }
    }
}
