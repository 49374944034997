.acc-detail-wrapper {
    background: $dark-gray;
    .accordion-item {
        text-align: center;
        .accordion-title {
            padding: 1rem 0;
            display: block;
            font-size: rem-calc(18);
            text-decoration: none;
            color: $white;
            &:hover {
                text-decoration: none;
            }
            &:focus {
                outline: 0 !important;
            }
            &:before {
                vertical-align: text-bottom;
                position: relative;
                margin-left: -1rem;
                margin-right: 1rem;
                content: "\2212";
                top: rem-calc(2);
                right: initial;
                width: auto;
                height: auto;
                left: initial;
                font-size: rem-calc(28);
            }
            .action {
                line-height: 1;
                &:before{
                    content: "Hide";
                }
            }
        }
    }
    .accordion-item:not(.is-active) .accordion-title {
        color: $white;
        &:before {
            content: "\002B";
        }
        .action {
            &:before{
                content: "Show";
            }
        }
    }
    .accordion-content {
        background: $off-white;
        border-top: 2px solid #ccc;
        text-align: left;
        padding: 1rem 0;
        .details {
            border-right: $global-border;
            margin-right: 2rem;
            padding: 1rem;
            &.last {
                border-right: 0;
            }
        }
        .title {
            @extend .sec-font;
            font-size: rem-calc(18);
            font-weight: 700;
        }
        .icon {
            font-size: rem-calc(40);
        }
        .reorder-link {
            @extend small;
            line-height: 1;
        }
    }
}
