@import '../../../styles/vendor/icomoon/variables/variables';

$callout-icon-margin: strip-unit($callout-icon-size);

%callout-icon-padding{
    padding: $callout-padding;
}
.callout{
    position: relative;
    padding: $callout-padding;
    background-color: $cas-note-overlay;
    color: $body-font-color;
    display: flex;
    flex-wrap: wrap;
    &:before{
        font-family: '#{$icomoon-font-family}' !important;
        content: $cas-i-notification;
        color: get-color(primary);
        margin-right: rem-calc(10);
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 1rem;
    }
    &-content{
        padding: $callout-padding;
        display: inline-block;
    }
    .close-button{
        top: 0;
    }
    > * {
        flex: 0 0 100%;
        max-width: 100%;
    }
    > :last-child, p:last-of-type {
        margin-bottom: 0;
    }
}
.closeable{
    &>:nth-last-child(2){
        margin-bottom: 0;
        display: inline-block;
    }
}
@include callout(success);
@include callout(error);
@include callout(alert);
@include callout(warning);
