
%smallFont {
    font-size: $small-font-size;
    line-height: 1.2;
}

.dropdown {
    .account-menu {
        &.mini-cart {
            padding: 1.5rem 1rem;
            min-width: 400px;
            .block-minicart .minicart-content-wrapper {
                height: auto !important;
                .block-content {
                    padding: 1rem;
                }
            }
            .minicart-content-box {
                height: auto !important;
                max-height: rem-calc(500);
                overflow-y: auto;
            }
        }
    }
}

.minicart {
    &-header {
        font-size: rem-calc(16);
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 2px solid $medium-gray;
    }
    &-total {
        border-top: 2px solid $medium-gray;
        padding-top: 1rem;
        margin: 1.5rem auto 1rem;
        font-size: rem-calc(16);
        text-align: right;
        font-weight: 700;
        &.empty {
            font-size: 1rem;
        }
        &-item {
            font-size: 1rem;
            font-weight: 600;
        }
    }
    &-shipping {
        background-color: get-color('blue');
        color: $white;
        padding: .35rem 2rem;
        margin: 1rem 0;
        .truck-icon {
            vertical-align: middle;
            margin-right: .5rem;
            max-width: 1rem;
        }
        .trashcan-icon {
            vertical-align: middle;
            min-width: 1rem;
        }
        small {
            vertical-align: middle;
        }
    }
    &-content {
        position: relative;
        &-count {
            position: absolute;
            bottom: 1.25rem;
            right: 0;
            border-radius: 5000px;
            padding: 0 .35rem;
            line-height: 1.5;
            text-align: center;
            font-size: rem-calc(12);
            background: get-color('secondary');
            color: $white;
        }
    }
    &-action {
        margin: 2rem 0 1.5rem;
        a.button {
            padding: $button-padding;
            &:hover {
                color: $white;
            }
            &:after {
                top: 0;
                right: initial;
                left: -20%;
            }
        }
    }
    &-price {
        font-weight: 600;
    }
}

.minicart-item {
    padding: 1.25rem 0;
    border-bottom: 1px solid $ui-light-gray;
    &.last, &:last-child {
        border-bottom: 0;
    }
    &-img {
        img {
            margin-right: 1rem;
            max-width: 50px;
        }
    }
    &-title {
        a.title, .title {
            color: $dark-gray;
            display: inline-block;
            margin-bottom: rem-calc(10);
            font-weight: 400;
            line-height: 1.2;
        }
    }
    &-qty {
        width: 4rem;
        margin-left: rem-calc(5);
        @extend %smallFont;
        height: rem-calc(40);
    }
}

@media screen and #{breakpoint(large)} {
    .minicart {
        &-content {
            &-count {
                right: initial;
                left: 1rem;
            }
        }
    }
}
