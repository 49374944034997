%no-border {
    border: 0;
}

.button {
    @include disable-mouse-outline;
    cursor: pointer;
    font-weight: $global-weight-bold;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 3;
    @extend %no-border;
    box-shadow: $button-box-shadow;
    color: $white;
    &.small {
        padding: 0.75rem 1rem;
    }
    &:after{
        position: absolute;
        transition: $button-transition;
        content: '';
        width: 0;
        bottom: 0;
        height: 120%;
        left: -20%;
        transform: skewX(-45deg);
        z-index: -1;
    }
    &:hover {
        background-color: get-color(primary);
        border: 0;
        &:after{
            position: absolute;
            left: -20%;
            width: 72.5%;
        }
    }
    &:active {
        @extend %no-border;
        &:after{
            position: absolute;
            width: 72.5%;
            left: -20%;
        }
    }
    &.primary {
        background-color: $button-background;
        border: 0;
        &:hover {
            background-color: $button-background;
            &:after {
                background-color: $button-background-hover;
            }
        }
    }
    &.secondary {
        background-color: darken(get-color(secondary), 5%);
        &:hover {
            background-color: darken(get-color(secondary), 5%);
            &:after {
                background-color: #c0112e;
            }
        }
    }
    &.disabled {
        background: darken($ui-gray, 5%) !important;
        box-shadow: none;
        &:hover, &:active, &:focus {
            @extend .disabled;
            &:after {
                width: 0;
            }
        }
    }
    &.hollow {
        @include transition($button-transition);
        border: $button-hollow-border;
        box-shadow: none;
        background-color: transparent;
        &:hover, &:active, &:focus {
            border: $button-hollow-border;
            background-color: rgba($button-background, .2);
            &:after {
                background-color: transparent;
            }
        }
    }
    &.flat {
        border: 0 !important;
        background: none !important;
        box-shadow: none !important;
        &:hover {
            background: transparent;
            &:after {
                background-color: transparent;
            }
        }
    }
    &.small-expanded {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    > * {
        line-height: 1;
    }
}

@media screen and #{breakpoint(medium)}{
    .button {
        padding: $button-padding-large;
        &.small-expanded {
            width: auto;
            padding: $button-padding-large;
        }
    }
}

@include cas-btn('base-coral', 'navy', 'solid');
@include neut-btn('ui-gray', $ui-gray, $btn-type: 'flat');
@include neut-btn('medium-gray', $medium-gray, $btn-type: 'flat');
@include neut-btn('dark-gray', $dark-gray, $btn-type: 'flat');
@include neut-btn('ui-gray', $ui-gray, $btn-type: 'hollow');
@include neut-btn('medium-gray', $medium-gray, $btn-type: 'hollow');
@include neut-btn('dark-gray', $dark-gray, $btn-type: 'hollow');
