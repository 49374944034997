.page-footer {
    overflow-x: hidden;
    .newsletter {
        background: get-color('vanilla');
        padding: 2rem 1rem 3rem;
        .subscribe {
            margin-top: 0.5rem;
            @include cas-btn($btn-name:'green', $btn-color:'green');
            .button {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .heading {
            font-size: rem-calc(22);
            line-height: 1;
            display: inline-block;
            margin-bottom: 1rem;
            .hl-txt {
                color: get-color(secondary);
            }
        }
        .policy {
            margin: rem-calc(5 0 0);
        }
    }
    .sub-policy {
        @extend .dark-link;
        @extend .u-link;
    }
}

@media screen and #{breakpoint(medium)} {
    .page-footer {
        .newsletter {
            .heading {
                margin-bottom: .25rem;
            }
            .subscribe {
                label {
                    display: inline-block;
                    vertical-align: middle;
                    width: 60%;
                    > input {
                        margin: rem-calc(0 7 0 0);
                    }
                }
                .button {
                    margin-left: 0.5rem;
                    display: inline-block;
                    width: calc(40% - 1rem);
                }
            }
        }
    }
}
@media screen and #{breakpoint(large)} {
    .page-footer {
        .newsletter {
            padding: 1rem 0 0.5rem;
            .heading {
                font-size: rem-calc(22);
                margin-bottom: .25rem;
            }
            .subscribe {
                margin-top: 0;
            }
        }
        .sub-form {
            .policy {
                font-size: rem-calc(15);
                margin: rem-calc(8 0 0 20);
            }
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .page-footer {
        .newsletter {
            .heading {
                font-size: rem-calc(24);
            }
        }
    }
}
