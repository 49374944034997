@import "variables/variables";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot');
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="cas-i-"], [class*=" cas-i-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cas-i-order-history {
    &:before {
        content: $cas-i-order-history;
    }
}
.cas-i-plus-circle {
    &:before {
        content: $cas-i-plus-circle;
    }
}
.cas-i-asc-arrow {
    &:before {
        content: $cas-i-asc-arrow;
    }
}
.cas-i-condition {
    &:before {
        content: $cas-i-condition;
    }
}
.cas-i-desc-arrow {
    &:before {
        content: $cas-i-desc-arrow;
    }
}
.cas-i-in-cart {
    &:before {
        content: $cas-i-in-cart;
    }
}
.cas-i-order-review {
    &:before {
        content: $cas-i-order-review;
    }
}
.cas-i-qa {
    &:before {
        content: $cas-i-qa;
    }
}
.cas-i-quick-reorder {
    &:before {
        content: $cas-i-reorder;
    }
}
.cas-i-return {
    &:before {
        content: $cas-i-return;
    }
}
.cas-i-small-grid {
    &:before {
        content: $cas-i-small-grid;
    }
}
.cas-i-star-rating {
    &:before {
        content: $cas-i-star-rating;
    }
}
.cas-i-success {
    &:before {
        content: $cas-i-success;
    }
}
.cas-i-menu {
    &:before {
        content: $cas-i-menu;
    }
}
.cas-i-yelp {
    &:before {
        content: $cas-i-yelp;
    }
}
.cas-i-lquo {
    &:before {
        content: $cas-i-lquo;
    }
}
.cas-i-amazon-pay {
    &:before {
        content: $cas-i-amazon-pay;
    }
}
.cas-i-address-book {
    &:before {
        content: $cas-i-address-book;
    }
}
.cas-i-alarm2 {
    &:before {
        content: $cas-i-alarm2;
    }
}
.cas-i-amex {
    &:before {
        content: $cas-i-amex;
    }
}
.cas-i-arrow-down {
    &:before {
        content: $cas-i-arrow-down;
    }
}
.cas-i-arrow-down2 {
    &:before {
        content: $cas-i-arrow-down2;
    }
}
.cas-i-arrow-left {
    &:before {
        content: $cas-i-arrow-left;
    }
}
.cas-i-arrow-left2 {
    &:before {
        content: $cas-i-arrow-left2;
    }
}
.cas-i-arrow-right {
    &:before {
        content: $cas-i-arrow-right;
    }
}
.cas-i-arrow-right2 {
    &:before {
        content: $cas-i-arrow-right2;
    }
}
.cas-i-arrow-up {
    &:before {
        content: $cas-i-arrow-up;
    }
}
.cas-i-arrow-up2 {
    &:before {
        content: $cas-i-arrow-up2;
    }
}
.cas-i-attachment {
    &:before {
        content: $cas-i-attachment;
    }
}
.cas-i-bell {
    &:before {
        content: $cas-i-bell;
    }
}
.cas-i-finder {
    &:before {
        content: $cas-i-finder;
    }
}
.cas-i-blocked {
    &:before {
        content: $cas-i-blocked;
    }
}
.cas-i-knowledge-base {
    &:before {
        content: $cas-i-knowledge-base;
    }
}
.cas-i-bookmark {
    &:before {
        content: $cas-i-bookmark;
    }
}
.cas-i-books {
    &:before {
        content: $cas-i-books;
    }
}
.cas-i-briefcase {
    &:before {
        content: $cas-i-briefcase;
    }
}
.cas-i-bubble {
    &:before {
        content: $cas-i-bubble;
    }
}
.cas-i-bubble2 {
    &:before {
        content: $cas-i-bubble2;
    }
}
.cas-i-bubbles {
    &:before {
        content: $cas-i-bubbles;
    }
}
.cas-i-chat {
    &:before {
        content: $cas-i-chat;
    }
}
.cas-i-bubbles3 {
    &:before {
        content: $cas-i-bubbles3;
    }
}
.cas-i-bubbles4 {
    &:before {
        content: $cas-i-bubbles4;
    }
}
.cas-i-busy {
    &:before {
        content: $cas-i-busy;
    }
}
.cas-i-camera {
    &:before {
        content: $cas-i-camera;
    }
}
.cas-i-video {
    &:before {
        content: $cas-i-video;
    }
}
.cas-i-error {
    &:before {
        content: $cas-i-error;
    }
}
.cas-i-cart {
    &:before {
        content: $cas-i-cart;
    }
}
.cas-i-checkmark {
    &:before {
        content: $cas-i-checkmark;
    }
}
.cas-i-checkmark2 {
    &:before {
        content: $cas-i-checkmark2;
    }
}
.cas-i-clock {
    &:before {
        content: $cas-i-clock;
    }
}
.cas-i-cross {
    &:before {
        content: $cas-i-cross;
    }
}
.cas-i-gear {
    &:before {
        content: $cas-i-gear;
    }
}
.cas-i-coin {
    &:before {
        content: $cas-i-coin;
    }
}
.cas-i-logo-icon {
    &:before {
        content: $cas-i-logo-icon;
    }
}
.cas-i-copy {
    &:before {
        content: $cas-i-copy;
    }
}
.cas-i-clipboard {
    &:before {
        content: $cas-i-clipboard;
    }
}
.cas-i-card {
    &:before {
        content: $cas-i-card;
    }
}
.cas-i-discover {
    &:before {
        content: $cas-i-discover;
    }
}
.cas-i-save {
    &:before {
        content: $cas-i-save;
    }
}
.cas-i-download {
    &:before {
        content: $cas-i-download;
    }
}
.cas-i-download2 {
    &:before {
        content: $cas-i-download2;
    }
}
.cas-i-download3 {
    &:before {
        content: $cas-i-download3;
    }
}
.cas-i-drawer {
    &:before {
        content: $cas-i-drawer;
    }
}
.cas-i-drawer2 {
    &:before {
        content: $cas-i-drawer2;
    }
}
.cas-i-drawer3 {
    &:before {
        content: $cas-i-drawer3;
    }
}
.cas-i-droplet {
    &:before {
        content: $cas-i-droplet;
    }
}
.cas-i-enter {
    &:before {
        content: $cas-i-enter;
    }
}
.cas-i-email {
    &:before {
        content: $cas-i-email;
    }
}
.cas-i-exit {
    &:before {
        content: $cas-i-exit;
    }
}
.cas-i-eye {
    &:before {
        content: $cas-i-eye;
    }
}
.cas-i-eye2 {
    &:before {
        content: $cas-i-eye2;
    }
}
.cas-i-eye-blocked {
    &:before {
        content: $cas-i-eye-blocked;
    }
}
.cas-i-facebook {
    &:before {
        content: $cas-i-facebook;
    }
}
.cas-i-file {
    &:before {
        content: $cas-i-file;
    }
}
.cas-i-file2 {
    &:before {
        content: $cas-i-file2;
    }
}
.cas-i-file3 {
    &:before {
        content: $cas-i-file3;
    }
}
.cas-i-file4 {
    &:before {
        content: $cas-i-file4;
    }
}
.cas-i-file-pdf {
    &:before {
        content: $cas-i-file-pdf;
    }
}
.cas-i-film {
    &:before {
        content: $cas-i-film;
    }
}
.cas-i-filter {
    &:before {
        content: $cas-i-filter;
    }
}
.cas-i-flag {
    &:before {
        content: $cas-i-flag;
    }
}
.cas-i-folder {
    &:before {
        content: $cas-i-folder;
    }
}
.cas-i-folder-open {
    &:before {
        content: $cas-i-folder-open;
    }
}
.cas-i-forward {
    &:before {
        content: $cas-i-forward;
    }
}
.cas-i-gift-box {
    &:before {
        content: $cas-i-gift-box;
    }
}
.cas-i-google {
    &:before {
        content: $cas-i-google;
    }
}
.cas-i-google-plus {
    &:before {
        content: $cas-i-google-plus;
    }
}
.cas-i-large-grid {
    &:before {
        content: $cas-i-large-grid;
    }
}
.cas-i-heart {
    &:before {
        content: $cas-i-heart;
    }
}
.cas-i-heart2 {
    &:before {
        content: $cas-i-heart2;
    }
}
.cas-i-history {
    &:before {
        content: $cas-i-history;
    }
}
.cas-i-home {
    &:before {
        content: $cas-i-home;
    }
}
.cas-i-address {
    &:before {
        content: $cas-i-address;
    }
}
.cas-i-image2 {
    &:before {
        content: $cas-i-image2;
    }
}
.cas-i-info {
    &:before {
        content: $cas-i-info;
    }
}
.cas-i-key2 {
    &:before {
        content: $cas-i-key2;
    }
}
.cas-i-eco-leaf {
    &:before {
        content: $cas-i-eco-leaf;
    }
}
.cas-i-chain {
    &:before {
        content: $cas-i-chain;
    }
}
.cas-i-list-view {
    &:before {
        content: $cas-i-list-view;
    }
}
.cas-i-pin {
    &:before {
        content: $cas-i-pin;
    }
}
.cas-i-lock {
    &:before {
        content: $cas-i-lock;
    }
}
.cas-i-mastercard {
    &:before {
        content: $cas-i-mastercard;
    }
}
.cas-i-meter {
    &:before {
        content: $cas-i-meter;
    }
}
.cas-i-moneyback {
    &:before {
        content: $cas-i-moneyback;
    }
}
.cas-i-newspaper {
    &:before {
        content: $cas-i-newspaper;
    }
}
.cas-i-external {
    &:before {
        content: $cas-i-external;
    }
}
.cas-i-notax {
    &:before {
        content: $cas-i-notax;
    }
}
.cas-i-knowledge {
    &:before {
        content: $cas-i-knowledge;
    }
}
.cas-i-notification {
    &:before {
        content: $cas-i-notification;
    }
}
.cas-i-corporate {
    &:before {
        content: $cas-i-corporate;
    }
}
.cas-i-order-tracking {
    &:before {
        content: $cas-i-order-tracking;
    }
}
.cas-i-paste {
    &:before {
        content: $cas-i-paste;
    }
}
.cas-i-paste2 {
    &:before {
        content: $cas-i-paste2;
    }
}
.cas-i-paste3 {
    &:before {
        content: $cas-i-paste3;
    }
}
.cas-i-paypal {
    &:before {
        content: $cas-i-paypal;
    }
}
.cas-i-paypal2 {
    &:before {
        content: $cas-i-paypal2;
    }
}
.cas-i-paypal3 {
    &:before {
        content: $cas-i-paypal3;
    }
}
.cas-i-pencil {
    &:before {
        content: $cas-i-pencil;
    }
}
.cas-i-phone {
    &:before {
        content: $cas-i-phone;
    }
}
.cas-i-piggy-bank {
    &:before {
        content: $cas-i-piggy-bank;
    }
}
.cas-i-pinterest {
    &:before {
        content: $cas-i-pinterest;
    }
}
.cas-i-play {
    &:before {
        content: $cas-i-play;
    }
}
.cas-i-plus {
    &:before {
        content: $cas-i-plus;
    }
}
.cas-i-printer {
    &:before {
        content: $cas-i-printer;
    }
}
.cas-i-profile {
    &:before {
        content: $cas-i-profile;
    }
}
.cas-i-help {
    &:before {
        content: $cas-i-help;
    }
}
.cas-i-recycle {
    &:before {
        content: $cas-i-recycle;
    }
}
.cas-i-trashcan {
    &:before {
        content: $cas-i-trashcan;
    }
}
.cas-i-checklist {
    &:before {
        content: $cas-i-checklist;
    }
}
.cas-i-spam {
    &:before {
        content: $cas-i-spam;
    }
}
.cas-i-spinner {
    &:before {
        content: $cas-i-spinner;
    }
}
.cas-i-stack {
    &:before {
        content: $cas-i-stack;
    }
}
.cas-i-star {
    &:before {
        content: $cas-i-star;
    }
}
.cas-i-half-star {
    &:before {
        content: $cas-i-half-star;
    }
}
.cas-i-full-star {
    &:before {
        content: $cas-i-full-star;
    }
}
.cas-i-stopwatch {
    &:before {
        content: $cas-i-stopwatch;
    }
}
.cas-i-support {
    &:before {
        content: $cas-i-support;
    }
}
.cas-i-switch {
    &:before {
        content: $cas-i-switch;
    }
}
.cas-i-table {
    &:before {
        content: $cas-i-table;
    }
}
.cas-i-coupon {
    &:before {
        content: $cas-i-coupon;
    }
}
.cas-i-guarantee {
    &:before {
        content: $cas-i-guarantee;
    }
}
.cas-i-thumbs-down {
    &:before {
        content: $cas-i-thumbs-down;
    }
}
.cas-i-trophy {
    &:before {
        content: $cas-i-trophy;
    }
}
.cas-i-truck {
    &:before {
        content: $cas-i-truck;
    }
}
.cas-i-twitter {
    &:before {
        content: $cas-i-twitter;
    }
}
.cas-i-unlocked {
    &:before {
        content: $cas-i-unlocked;
    }
}
.cas-i-upload {
    &:before {
        content: $cas-i-upload;
    }
}
.cas-i-account {
    &:before {
        content: $cas-i-account;
    }
}
.cas-i-account2 {
    &:before {
        content: $cas-i-account2;
    }
}
.cas-i-visa {
    &:before {
        content: $cas-i-visa;
    }
}
.cas-i-wand {
    &:before {
        content: $cas-i-wand;
    }
}
.cas-i-warning {
    &:before {
        content: $cas-i-warning;
    }
}
.cas-i-wordpress {
    &:before {
        content: $cas-i-wordpress;
    }
}
.cas-i-troubleshoot {
    &:before {
        content: $cas-i-troubleshoot;
    }
}
.cas-i-youtube {
    &:before {
        content: $cas-i-youtube;
    }
}
.cas-i-ticket {
    &:before {
        content: $cas-i-ticket;
    }
}
.cas-i-store-credit {
    &:before {
        content: $cas-i-store-credit;
    }
}
.cas-i-rquo {
    &:before {
        content: $cas-i-rquo;
    }
}
.cas-i-search {
    &:before {
        content: $cas-i-search;
    }
}
.cas-i-warranty {
    &:before {
        content: $cas-i-warranty;
    }
}
