.page-main{
  margin: 3rem 0 4rem;
}
.page-title{
  @extend .work-sans;
  margin-top: 2rem;
    span {
        line-height: 1.2;
    }
}

//Svg Icons
.svg-icons{
  fill: $medium-gray;
  margin-right: .5rem;
  &.orange{
    fill: get-color('orange');
  }
  &.blue{
    fill: get-color('blue');
  }
  &.ui-gray{
    fill: $ui-gray;
  }
  &.link{
    &:hover{
      fill: get-color('cyan');
    }
  }
}

.po-box-field > label {
    margin-bottom: 0.5rem;
}
