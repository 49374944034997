.label {
    font-weight: 600;
    border-radius: 3px;
    background-color: get-color('cyan');
    color: $white;
    text-transform: capitalize;
    padding: rem-calc(7);
    &.magenta {
        background-color: get-color('magenta');
    }
    &.alert, &.orange {
        background-color: get-color('orange');
    }
    &.success, &.green, &.exclusive {
        background-color: #26A55F;
    }
    &.hollow {
        background: transparent;
        border: 2px solid get-color('cyan');
        color: get-color('cyan');
        &.magenta {
            color: get-color('magenta');
            border-color: get-color('magenta');
        }
        &.alert, &.orange {
            border-color: get-color('orange');
            color: get-color('orange');
        }
        &.exclusive, &.success, &.green {
            border-color: #26A55F;
            color: #26A55F;
        }
    }
}
