[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    padding: rem-calc(10.5 15);
    height: auto;
    width: 100%;
    margin-top: 0.5rem;
}

[type='hidden']{
    width: 0;
    height: 0;
}

input::-webkit-input-placeholder {
  font-size: rem-calc(15);
    color: $medium-gray;
}

.input-required {
  display: inline;
  color: get-color(alert);
}
