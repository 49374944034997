.nxt-search-by {
    &-img {
        padding: 0.25rem;
        border: $global-border;
        border-radius: $global-radius;
        margin: 1rem 0;
    }
}

.nxt-search-help {
    margin-top: 2rem;
    .h3 {
        margin-bottom: 1rem;
    }
}

@media screen and #{breakpoint(medium)} {
    .nxt-search-by {
        margin-bottom: 1rem;
        &-content{
            margin: 1rem 0 0 1rem;
        }
    }
}
