.help-text {
  font-weight: normal;
  margin-top: 0.15rem;
  margin-bottom: .35rem;
  display: block;
  line-height: 1.2;
}

fieldset {
  .help-text {
    margin-top: .15rem;
  }
}
