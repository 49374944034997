%footer-bg {
    background-color: #232222;
    color: get-color('vanilla');
}

%footer-title {
    font-size: rem-calc(19);
    margin: 0 0 .5rem;
}

.footer {
    padding: 1rem;
    &.content {
        @extend %footer-bg;
    }
    &.policy {
        background: #f7f7f7;
        padding: 0.5rem 1rem;
        p {
            margin: 0;
        }
    }
}
.social-block-title {
    font-size: rem-calc(18);
}

.copyright {
    margin: 1rem auto;
}

.medium-text-right {
    text-align: left;
}

.footer-links {
    .accordion {
        padding: 0 1rem;
        border-bottom: 1px solid $light-gray;
        &:last-child {
            border: 0;
        }
    }
    .fl-title, .accordion-title {
        @extend %footer-title;
        color: get-color('vanilla');
    }
    .accordion-item:not(.is-active), .accordion-item.is-active {
        .accordion-title{
            font-size: rem-calc(18);
            @extend %footer-title;
            color: get-color('vanilla');
            font-weight: 700;
            &:before {
                font-size: rem-calc(24);
                margin-top: rem-calc(-13);
                color: get-color('vanilla');
            }
        }
    }
    ul {
        margin: 0;
        li {
            margin: 1.5rem 0;
            &:first-child {
                margin-top: .5rem;
            }
        }
    }
    a {
        border: 0;
        color: get-color('vanilla');
        font-weight: 400;
        &:hover {
            color: darken(get-color('vanilla'), 10%);
        }
    }
}
.footer-co-info {
    margin: 1rem auto;
    .v-card {
        display: inline-block;
        text-align: center;
        .store-info {
            text-align: left;
        }
    }
}
.social-media {
    margin: 1.5rem 0;
    span {
        color: $white;
    }
    &-icons {
        color: $white;
        font-size: rem-calc(26);
    }
}

.v-card {
    margin-top: .75rem;
    small {
        font-size: 1rem;
    }
    .logo-icon {
        height: rem-calc(45);
        margin-top: .5rem;
        margin-right: 1rem;
    }
    .store-hours {
        margin-bottom: 0;
    }
}

// Responsiveness

@media screen and #{breakpoint(medium)} {
    .footer {
        &.content {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }
    .social-media-icons {
        font-size: rem-calc(22);
    }
    .footer-links {
        ul {
            li:first-child {
                margin-top: .75rem;
            }
        }
    }
    .footer-co-info {
        max-width: 45%;
        margin: 1rem auto 0;
    }
    .medium-text-right {
        text-align: right;
    }
}

@media screen and #{breakpoint(large)} {
    .footer-links {
        .accordion-item:not(.is-active), .accordion-item.is-active {
            .accordion-title {
                padding: 0 0 0.5rem;
                &:before {
                    display: none;
                }
            }
        }
    }
    .footer-co-info {
        max-width: 30%;
        margin-top: 0;
        margin-left: 1.5rem;
    }
}

@media screen and #{breakpoint(xlarge)} {
    footer {
        margin-top: 5rem;
    }
    .footer-links {
        ul {
            li {
                margin: .5rem 0;
            }
        }
    }
    .copyright {
        margin: .5rem auto;
    }
}
