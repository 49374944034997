.dropdown {
    &.menu {
        a {
            margin: 0;
            padding: 0;
            border: 0;
            .menu-caret {
                color: $dark-gray;
                font-size: rem-calc(10);
            }
            &:hover {
                border: 0;
                .menu-caret {
                    color: get-color('cyan');
                }
            }
        }
        li {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.dropdown.middle > li.opens-right > .is-dropdown-submenu {
    left: -50%;
    margin-left: -.4rem;
}

.dropdown.middle > li.opens-left > .is-dropdown-submenu {
    right: -50%;
    margin-right: -.4rem;
}

.is-dropdown-submenu {
    z-index: 60;
}
