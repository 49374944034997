.review-file-uploader{
    border: 2px dashed $ui-light-gray;
    padding: 5rem 1rem;
    text-align: center;
    background-color: #fefefe;
    &.focus {
        background-color: get-color('ice');
        color: $ui-light-gray !important;
        opacity: 0.5;
    }
    .uploader-area{
        .label-text{
            font-size: rem-calc(18);
            color: $medium-gray;
            &:before{
                content: url('../images/icons/upload.svg');
                width: rem-calc(20);
                height: rem-calc(20);
                margin-right: 1rem;
                vertical-align: middle;
                opacity: 0.5;
                display: inline-block;
            }
        }
        input[type="file"]{
            width: 1px;
            height: 1px;
            opacity: 0;
            overflow: hidden;
        }
    }
    .uploader-drop-zone {
        .uploader-placeholder-text {
            margin-top: 1rem;
        }
    }
    .file-uploader-summary {
        margin-top: 2rem;
    }
    .preview-img {
        display: block;
        margin-bottom: 1rem;
    }
    .file-name {
        margin-top: 1rem;
    }
    .file-link {
        color: $dark-gray;
    }
}

@media screen and #{breakpoint(medium)} {
    .review-file-uploader {
        .uploader-area {
            .label-text {
                font-size: rem-calc(30);
                &:before {
                    width: rem-calc(50);
                    height: rem-calc(50);
                }
            }
        }
    }
}
