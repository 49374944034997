.header-search, .search-form-wrapper {
    .form {
        margin: 0
    }
    .pill-form {
        .input-text {
            padding-left: 2.75rem;
        }
        .button {
            width: 30px;
            left: 0;
            background: transparent;
            color: $medium-gray;
            box-shadow: none;
            &.icon{
                padding: 1rem 0 1rem 1rem;
            }
            .search-icon-w {
                font-size: 1rem;
                color: darken($medium-gray, 5%);
            }
            &:hover{
                background: transparent;
                &:after {
                    background: transparent;
                }
            }
        }
    }
}
.head-wrapper {
    .mobile-account-menu {
        flex: 0 0 100%;
        width: 100%;
    }
}
.search-col {
    flex: 0 0 100%;
    width: 100%;
}
#algoliaAutocomplete {
    margin-top: 1rem;
    margin-bottom: 0 !important;
}
.aa-InputWrapper {
    input[type=search] {
        margin: 0;
        padding-left: 3rem;
    }
}

#algoliaAutocomplete .aa-Form .aa-SubmitButton {
    right: initial;
    left: 10px;
    top: 5px;
}
#algoliaAutocomplete .aa-SubmitIcon {
    fill: $medium-gray;
}


.aa-Panel .aa-List .aa-Item {
    &:before {
        display: none;
    }
    .algoliasearch-autocomplete-hit {
        .info {
            color: $dark-gray;
        }
    }
    .algoliasearch-autocomplete-price {
        color: get-color(primary);
    }
}

@media screen and #{breakpoint(medium)} {
    #algoliaAutocomplete {
        margin-top: 0;
    }
    .search-col {
        flex: 1 1 auto;
        width: auto;
    }
    .header-right-col {
        flex: 0 0 auto;
    }
    .header-search {
        .form {
            margin-left: 1rem;
        }
    }
}
