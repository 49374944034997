.orbit-container {
  @include disable-mouse-outline;
  .orbit-slide {
    padding: 0;
    text-indent: initial;
    &:before {
      display: none !important;
    }
  }
}

.orbit-previous, .orbit-next {
  svg {
    display: inline-block;
    height: rem-calc(32);
    cursor: pointer;
    fill: $white;
    webkit-filter: drop-shadow($orbit-control-box-shadow);
    filter: drop-shadow($orbit-control-box-shadow);
  }
  &:hover {
    svg {
      fill: $ui-gray;
    }
  }
}

.orbit-bullets {
  button {
    cursor: pointer;
  }
}

.orbit {
  &.block-slider {
    position: relative;
    .orbit-controls {
      visibility: hidden;
    }
    .orbit-wrapper {
      &:hover {
        .orbit-controls {
          visibility: visible;
        }
      }
    }
    .orbit-bullets {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      button {
        border: 1px solid $ui-gray;
        background-color: $white;
        &.is-active {
          position: relative;
          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -3px;
            margin-left: -3px;
            content: '';
            width: 6px;
            height: 6px;
            background-color: $medium-gray;
            display: inline-block;
            border-radius: 50%;
          }
        }
      }
    }
  }
}


@media screen and #{breakpoint(medium)} {
  .orbit{
    .items {
      margin: 0 1.25rem 0 0;
      padding: 0 2rem;
      display: flex;
      li {
        flex: 0 0 1px;
        padding: 0;
        margin: 0;
        text-indent: initial;
        max-width: 100%;
        >div{
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and #{breakpoint(xlarge)} {
  .orbit{
    .items {
      margin: 0 1.25rem 0 0;
      padding: 0 2rem;
      li {
        flex: 0 0 calc(25% - 1rem);
        max-width: calc(25% - 1rem);
      }
    }
  }
}