.ui-gray-icons{
  color: $ui-gray;
  font-size: rem-calc(22);
  margin-right: .5rem;
  vertical-align: middle;
}
.default-icons{
  display: inline-block;
  color: darken($ui-gray, 10%);
  font-size: rem-calc(17);
  margin-right: .5rem;
  vertical-align: middle;
}
.light-gray-icons{
  display: inline-block;
  color: $ui-light-gray;
  font-size: rem-calc(17);
  vertical-align: middle;
  margin-right: .5rem;
}
.search-icon{
  font-size: rem-calc(24);
  color: $dark-gray;
  &-w{
    color: $white;
    font-size: rem-calc(20);
  }
}