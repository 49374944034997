.is-invalid-input:not(:focus) {
    background: $input-background-invalid;
    border: 1px solid get-color(alert);
}

input.mage-error:not(:focus) {
    background: $input-background-invalid;
    border: 1px solid get-color(alert);
}

.is-invalid-input {
    margin-bottom: 0;
}

.form-error-msg, .form-error, div.mage-error {
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    font-style: italic;
    position: relative;
    line-height: 1.2;
}
.form-error-msg {
    display: none;
}
div.mage-error {
    color: get-color('alert');
}
.form-error {
    display: none;
    &.ui-comp {
        display: block;
    }
}

fieldset {
    .form-error-msg {
        margin-top: -.5rem;
        margin-bottom: .5rem;
    }
}

.input-group.inline-label {
    + .form-error-msg {
        margin-top: 0.25rem;
    }
}
