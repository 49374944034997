.accordion-title {
    font-weight: 700;
    color: $accordion-item-color;
    cursor: pointer;
    padding-left: rem-calc(30);
    &:active, &:focus {
        color: $accordion-item-color;
        text-decoration: none;
    }
    &:hover, &:active {
        color: $dark-gray;
        border: 0;
        text-decoration: none;
        &:before {
            content: "\002B";
        }
    }
    &:before {
        display: inline-block;
        right: initial;
        left: 0;
        margin-top: rem-calc(-16);
        content: "\002B";
        font-size: rem-calc(30);
    }
}

.accordion-item {
    &:last-child {
        border-bottom: 0;
    }
    &.is-active {
        .accordion-title {
            color: $dark-gray;
            &:before {
                content: "\002D";
            }
        }
    }
    &:not(.is-active) {
        .accordion-title {
            color: $accordion-item-color;
            &:before {
                content: "\002B";
            }
        }
    }
    &.current {
        &.is-active {
            .accordion-content {
                display: block !important;
            }
        }
        border-bottom: 0;
        display: block !important;
    }
}

@media screen and #{breakpoint(medium)} {
    .accordion-title {
        &:before {
            width: 17px;
            height: 17px;
        }
    }
}
