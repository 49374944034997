.breadcrumbs {
    margin: 3rem 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    &-mobile {
        .bc-prev {
            color: $medium-gray;
            font-size: rem-calc(12);
            margin-right: .35rem;
        }
    }
    li {
        display: inline;
        float: none;
        position: relative;
        margin: 0 0.35rem 0 0;
        a {
            font-weight: $global-weight-normal;
            border-bottom: 0;
            position: relative;
            padding-bottom: rem-calc(9);
            &:hover {
                text-decoration: underline;
                &:after {
                    width: 100%;
                }
            }
        }
        &:after {
            font-size: $breadcrumbs-item-separator-size;
        }
        &:hover {
            position: relative;
            &:before {
                border-bottom: 0;
            }
        }
        &:not(:last-child)::after {
            margin-right: .35rem;
            font-size: $breadcrumbs-item-separator-size;
        }
    }
    .current {
        font-weight: $global-weight-bold;
        padding-bottom: rem-calc(10);
        position: relative;
        color: $dark-gray;
    }
}
