@import "../../../Magento_Search/styles/header-searchbar";
.page-header {
    box-shadow: $global-box-shadow;
    position: relative;
}
.top-header {
    background: lighten(get-color('vanilla'), 5%);
    padding: .5rem 0;
    .ship-link {
        font-weight: 700;
        color: darken(get-color(primary), 10%);
        &:hover {
            color: get-color(primary);
        }
    }
    .cou-link{
        font-weight: 700;
        color: darken(get-color('secondary'), 10%);
        &:hover {
            color: get-color('secondary');
        }
    }
}
.head-wrapper {
    justify-content: space-between;
}
.mobile-menu-icon {
    color: $dark-gray;
    font-size: rem-calc(22);
}

.logo {
    &-wrapper {
        min-width: rem-calc(140);
        img {
            min-width: rem-calc(160);
        }
    }
    display: block;
    margin: 0;
    cursor: pointer;
}
.header-search {
    margin-top: 1.5rem;
}

@include cas-btn('blue', 'blue', $btn-type: 'solid');
.main-header {
    padding: 1.5rem 0;
    .divider {
        font-size: rem-calc(16);
        line-height: 1;
        display: inline-block;
        margin-right: 0.5rem;
        vertical-align: top;
    }
    ul.account-menu {
        min-width: 300px;
        padding: 2rem 1.5rem;
        > li.is-submenu-item a {
            &.register-link {
                margin: rem-calc(0 0 0 5);
                color: $body-font-color;
                text-decoration: underline;
                font-weight: 700;
                display: inline;
            }
        }
        .login-btn {
            margin: 0 auto 1.25rem;
            padding: rem-calc(13 (14*4));
            font-weight: 700;
            &:after {
                top: 0;
                right: initial;
                left: -20%;
            }
        }
    }
    .account-menu-action {
        text-align: center;
        .register {
            margin-bottom: 2rem;
        }
    }
    .account-menu-link {
        .account-menu-box {
            display: block;
            font-weight: 400 !important;
            margin-bottom: 0.5rem;
        }
    }
    .account-menu-logout {
        padding: 1.5rem 2rem .5rem;
    }
    .top-help {
        margin-top: 1.5rem;
        .top-help-links {
            li{
                margin: 1rem 0;
            }
        }
        .live-chat-status{
            margin-bottom: 0.25rem;
        }
    }
}


@media screen and (max-width: 320px) {
    .logo-wrapper {
        img {
            min-width: 80px;
        }
    }
    .top-account-menu.mobile li a {
        font-size: 1rem ;
    }
}

@media screen and #{breakpoint(medium)} {
    .top-account-menu.mobile li a {
        font-size: 1rem ;
    }
}

@media screen and #{breakpoint(large)} {
    %menu-link {
        cursor: pointer;
        line-height: 1;
    }
    .logo {
        img {
            min-width: rem-calc(200);
        }
        margin-left: 0;
    }
    .header {
        &.dropdown, &.menu {
            .menu-link {
                @extend %menu-link;
                .menu-caret {
                    margin-left: .25rem;
                    font-size: rem-calc(12);
                }
                a {
                    font-weight: 400;
                    padding: 0;
                }
            }
        }
    }
    .mobile-menu-icon {
        padding: 0 .25rem 0 0;
    }
    .menu-link {
        @extend %menu-link;
        a {
            color: $dark-gray;
            font-weight: 400;
            line-height: 1;
            &:hover {
                color: get-color(primary);
                text-decoration: none;
            }
        }
    }
    .top-header-link {
        border-right: 1px solid $ui-gray;
    }
    .main-header {
        background-color: $white;
    }
    .mini-cart {
        margin-left: 1rem;
    }
}

@media screen and #{breakpoint(medium)} {
    .header-search {
        margin-top: 0;
    }
    .main-header {
        padding: 2.5rem 0 1rem;
    }
}
