.form {
  &-wrapper {
    background: get-color('ice');
    padding: $form-spacing;
  }
  &-title {
    margin-bottom: 0;
    @extend .work-sans;
  }
  &-subtitle {
    margin-top: .25rem;
    margin-bottom: 1.25rem;
  }
  &-button {
    margin-top: 2rem;
  }
  &-extra-info {
    margin-top: 1rem;
  }
  &-inline {
    label{
      display: inline-block;
    }
    .input-label {
      display: block;
    }
    input {
      width: auto;
    }
    input[type="submit"], .form-button {
      margin: 0 0 0 .5rem;
      vertical-align: baseline;
    }
  }
}

.pill-form {
    position: relative;
    input {
        width: 100%;
        margin: 0 6% 0 0;
    }
    .button {
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        display: inline-block;
        box-shadow: inset 0 0px 5px rgba(get-color('midnight'), 0.25);;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        span {
            line-height: 1;
        }
        &.icon {
            padding: rem-calc(10.5 20);
        }
    }
}
.field.captcha {
    .label {
        display: block;
        font-size: 1rem;
        padding: 0;
        line-height: 1.2;
        color: $dark-gray;
        background: transparent;
    }
}
.captcha-reload {
    @extend .link;
    margin-top: 1rem;
    margin-left: 1rem;
    @media screen and #{breakpoint(medium)} {
        margin-top: 0;
    }
}



@import 'checkbox';
@import 'drag-uploader';
@import 'error';
@import 'fieldset';
@import 'help-text';
@import 'input-group';
@import 'select';
@import 'text';
