.switch {
    display: inline-block;
    margin: 0;
    color: $ui-gray;
    position: relative;
    .switch-paddle {
        background: $switch-background;
        width: 4.35rem;
        height: 2.25rem;
        vertical-align: middle;
        box-shadow: $input-shadow;
        border-radius: 5000px;
        margin: 0;
        transition: $switch-paddle-transition;
        border: 1px solid darken($light-gray, 10%);
        &:after {
            border-radius: 50%;
            box-shadow: none;
            border: 0;
            background-color: $ui-gray;
            left: .25rem;
        }
        &:hover {
            &:after {
                border: 0;
            }
        }
        &:before {
            display: none !important;
        }
    }
    .switch-input {
        width: rem-calc(68);
        height: rem-calc(34);
        left: auto;
        right: 0;
        top: 0;
        opacity: 0;
        &:checked + .switch-paddle:after {
            background-color: get-color('cyan');
            left: 2.25rem;
        }
        &:focus + .switch-paddle:after {
            box-shadow: 0 0 0 2px $black;
            outline: 2px solid transparent;
        }
    }
    .switch-label {
        font-size: 1rem;
        color: $dark-gray;
        vertical-align: middle;
        margin-left: 0.5rem;
    }
}
%switch-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.switch-active {
    @extend %switch-text;
    left: 17.5%;
    color: get-color('cyan');
    &-icon {
        font-size: rem-calc(15);
        color: get-color('cyan');
    }
}

.switch-inactive {
    @extend %switch-text;
    right: 17.5%;
    &-icon {
        font-size: rem-calc(13);
        color: $ui-gray;
    }
}
