// Custom Helpers for CAS
// - - - - - - - - - - - - - - -
// These are custom helpers applied to basic HTML tags, including:
//   - Work Sans font
//   - Ordered and unordered cmy-bullet
//   - External link, anchor link and link over dark-bg

@import '../../vendor/icomoon/variables/variables';

/// Default value for the custom links
$cas-dkbg-links-border-bottom: 2px dotted mix($light-gray, $medium-gray, 50%) !default;

.work-sans, .sec-font, .factoria {
    font-family: $sec-font;
}

.semibold-txt, .font-semibold {
    font-weight: 600;
}

.lh-1 {
    line-height: 1;
}

.lh-1_2 {
    line-height: 1;
}

.lh-1_4 {
    line-height: 1.4;
}

.lh-1_5 {
    line-height: 1.5;
}

.fs-15 {
    font-size: rem-calc(15);
}

.fs-16 {
    font-size: rem-calc(16);
}

.fs-20 {
    font-size: rem-calc(20);
}
.dk-txt {
    color: $dark-gray;
}
.green-txt {
    color: #008800;
}

.red-txt {
    color: darken(get-color(secondary), 10%);
}

.white-link {
    color: $white;
    &:hover {
        color: $light-gray;
    }
}

.u-link {
    text-decoration: underline;
    cursor: pointer;
}


.div-title {
    margin-top: 0;
}

.tiny-txt {
    font-size: rem-calc(12);
    line-height: 1.2;
}

a {
    &.button {
        color: $white;
    }
    &.plain {
        border: 0;
        color: $dark-gray;
        font-weight: normal;
    }
    &.underline-link {
        border: 0;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &.anchor-link, &.ext-link, &.external-links {
        margin-right: 1.25rem;
        &:after {
            position: absolute;
            font-family: '#{$icomoon-font-family}' !important;
            padding-left: rem-calc(3);
            vertical-align: top;
            font-size: rem-calc(12);
            font-weight: normal;
            display: inline;
            border-bottom: none;
        }
    }
    &.anchor-link {
        &:after {
            content: $cas-i-chain;
        }
    }
    &.ext-link, &.external-links {
        &:after {
            content: $cas-i-external;
        }
    }
    &.darkbg-link {
        color: lighten(get-color('cyan'), 15%);
        &:hover {
            color: get-color('cyan');
        }
    }
    &.dark-link {
        color: $dark-gray;
        &:hover {
            color: $anchor-color-hover;
        }
    }
    &.gray-link {
        color: $medium-gray;
        &:hover {
            color: $ui-gray;
        }
    }
}

.link {
    color: $anchor-color;
    line-height: $cas-links-line-height;
    font-weight: $cas-links-font-weight;
    cursor: pointer;
    &:hover, &:focus {
        color: $anchor-color-hover;
    }
    &.dark {
        color: $dark-gray;
        &:hover {
            color: $anchor-color-hover;
        }
    }
    &.gray {
        color: $medium-gray;
        &:hover {
            color: $ui-gray;
        }
    }
}

.valign-middle {
    vertical-align: middle;
}

.valign-top {
    vertical-align: top;
}

.valign-bottom {
    vertical-align: bottom;
}

.small-expanded {
    width: 100%;
}

.width-auto {
    width: auto;
}

.clear-both {
    clear: both;
}

.green-icon {
    fill: get-color('green');
    padding-right: .15rem;
}

.img-responsive {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
}

.hidden {
    display: none;
}

.display-flex {
    display: flex;
}

.limiter-label {
    display: inline-block;
    margin-right: 0.5rem;
}

.chat-online {
    color: get-color('green');
    &.hover {
        color: darken(get-color('green'), 5%);
    }
}

.chat-offline {
    color: get-color(secondary);
    &.hover {
        color: darken(get-color(secondary), 5%);
    }
}
.g-recaptcha {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
@media screen and #{breakpoint(medium)}{
    .small-expanded {
        width: initial;
    }
}
