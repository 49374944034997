table thead th, table thead td {
    border-bottom: 2px solid $medium-gray;
}

table tbody th, table tbody td {
    text-align: left;
    vertical-align: top;
}

%compact-padding {
    padding: rem-calc(15);
}
%border {
    border: $global-border;
}

table {
    &.compact {
        thead {
            th, td {
                @extend %compact-padding;
            }
        }
        tbody {
            th, td {
                @extend %compact-padding;
            }
        }
    }
    &.bordered {
        thead {
            th, td {
                @extend %border;
            }
        }
        tbody {
            th, td {
                @extend %border;
            }
        }
    }
}

.table-title {
    color: $dark-gray;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
