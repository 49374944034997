.mobile-main {
  margin-bottom: .75rem;
  &-btn {
    width: 100%;
    text-align: left;
    background-color: get-color('blue');
    color: $white;
    padding: rem-calc(12) 1rem;
    border-radius: 0;
    font-weight: 700;
    .icon {
      fill: $white;
      margin: rem-calc(-2 5 -2 0);
      &.right {
        transform: rotate(-90deg);
        margin: rem-calc(2 5 2 0);
        float: right;
      }
    }
  }
}

@media screen and #{breakpoint(large)} {
  .mobile-main {
    margin-bottom: 0;
    margin-right: .5rem;
    &-btn {
      width: initial;
      text-align: center;
      .icon {
        &.right {
          margin: rem-calc(-2 0 -2 10);
          transform: rotate(0deg);
          float: none;
        }
      }
    }
  }
}

@media screen and #{breakpoint(xlarge)} {
  .mobile-main {
    margin-right: 0;
    &-btn {
      margin-left: .75rem;
    }
  }
}
