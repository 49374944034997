@include breakpoint(large) {
  .card {
    padding: $card-padding;
  }
}

.card-section {
  position: relative;
  .label {
    position: absolute;
    @include breakpoint(large) {
      top: 0;
      left: 0;
    }
  }
}
