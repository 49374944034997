@use 'sass:math';

//Add Vendor Prefixes
@mixin prefix($map, $vendors: webkit moz ms o) {
  @each $prop, $value in $map {
    @if $vendors {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $prop}: #{$value};
      }
    }
    // Dump regular property anyway
    #{$prop}: #{$value};
  }
}

//Transform Scale
@mixin scaling($scale-value...) {
  @include prefix((transform:scale($scale-value)), ms webkit);
}

//Transition transform
@mixin transition($args...) {
  -webkit-transition: -webkit- $args;
  -moz-transition: -moz- $args;
  transition: $args;
}

//Opacity
@mixin opacity-rules($value:1) {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value * 100})";

  /* IE 5-7 */
  filter: alpha(opacity=$value * 100);

  /* Netscape */
  -moz-opacity: $value;

  /* Safari 1.x */
  -khtml-opacity: $value;
  opacity: $value;
}

//Letter spacing
@function em-letter-spacing($px-letter-spacing) {
    @return calc(#{$px-letter-spacing} / 1000  * 1em);
}
