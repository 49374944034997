.star-rating-container {
    margin-right: rem-calc(5);
    display: inline-block;
    vertical-align: middle;
    background: url('../images/icons/star-rating.svg') 0 0 repeat-x;
    height: 15px;
    width: 15px * 5;
    .star-rating {
        background: url('../images/icons/full-star.svg') 0 0 repeat-x;
        height: 15px;
    }
}
