.sp-icons {
    margin-right: 0.5rem;
    color: $medium-gray;
    font-size: rem-calc(22);
    display: inline-block;
}

.product-sub-wrapper {
    .sp-icons {
        color: $ui-light-gray;
        margin-right: 1rem;
        font-size: rem-calc(16);
        transform: rotate(-15deg);
        vertical-align: baseline;
    }
    .selling-points-text {
        font-weight: 600;
    }
}

.selling-points {
    &-text {
        vertical-align: middle;
    }
    a {
        vertical-align: middle;
    }

}

.sub-link {
    cursor: pointer;
    color: get-color('blue');
    text-decoration: underline;
    display: inline-block;
    margin-left: 1rem;
}

@media screen and #{breakpoint(medium)} {
    .selling-points {
        padding: rem-calc(0 10);
    }
}

@media screen and #{breakpoint(large)} {
    .selling-points {
        margin-top: 1rem;
    }
    .section-title {
        font-size: rem-calc(24);
    }
}
