//For branded color button
@mixin cas-btn($btn-name, $btn-color, $btn-type:'solid') {
    @if ($btn-type == 'hollow') {
        .button.#{$btn-name} {
            &.hollow {
                border: 2px solid lighten(get-color(#{$btn-color}), 20%);
                color: lighten(get-color(#{$btn-color}), 5%);
                &:hover, &:active, &:focus {
                    border: 2px solid lighten(get-color(#{$btn-color}), 20%);
                    background-color: rgba(get-color(#{$btn-color}), .2);
                }
            }
        }
    } @else {
        .button.#{$btn-name} {
            background-color: get-color(#{$btn-color});
            @if ($btn-name == 'yellow') {
                color: $dark-gray;
            }
            &:hover, &:active, &:focus {
                background-color: get-color(#{$btn-color});
                &:after {
                    background-color: scale-color(get-color(#{$btn-color}), $lightness: $button-background-hover-lightness);
                }
            }
        }
    }
}

//For custom color button (not included in branded color palette)
@mixin custom-btn($btn-name, $btn-color, $btn-type: 'solid') {
    @if ($btn-type == 'hollow') {
        .button.#{$btn-name} {
            &.hollow {
                border: 2px solid lighten($btn-color, 20%);
                background-color: transparent;
                color: lighten($btn-color, 5%);
                &:hover, &:active, &:focus {
                    border: 2px solid lighten($btn-color, 20%);
                    background-color: rgba($btn-color, .2);
                }
            }
        }
    } @else {
        .button.#{$btn-name} {
            box-shadow: 1px 1px 1px 0 rgba(get-color('midnight'), 0.65), $btn-color -10rem 0 0 0 inset;
            background-color: $btn-color;
            &:hover, &:active, &:focus {
                box-shadow: 1px 1px 1px 0 rgba(get-color('midnight'), 0.65), lighten($btn-color, 5%) 0 0 0 inset;
                background-color: scale-color($btn-color, $lightness: $button-background-hover-lightness);
            }
        }
    }
}

//For neutral color button (not included in branded color palette, doesn't have solid button type)
@mixin neut-btn($btn-name, $btn-color, $btn-type: 'hollow') {
    @if ($btn-type == 'flat') {
        .button.#{$btn-name} {
            color: $btn-color;
            &.flat {
                &:hover {
                    @if ($btn-name == 'medium-gray') {
                        color: lighten($btn-color, 20%);
                    } @else {
                        color: darken($btn-color, 20%);
                    }
                }
            }
        }
    } @else {
        .button.#{$btn-name} {
            @if ($btn-name == 'ui-gray') {
                color: darken($btn-color, 20%);
            } @else {
                color: $btn-color;
            }
            &.hollow {
                background-color: transparent;
                @if ($btn-name == 'ui-gray') {
                    border: 2px solid $btn-color;
                } @else {
                    border: 2px solid lighten($btn-color, 10%);
                }
                &:hover, &:active, &:focus {
                    @if ($btn-name == 'ui-gray') {
                        color: darken($btn-color, 20%);
                    } @else {
                        color: $btn-color;
                    }
                    background-color: rgba($btn-color, .2);
                    @if ($btn-name == 'ui-gray') {
                        border: 2px solid $btn-color;
                    } @else {
                        border: 2px solid lighten($btn-color, 10%);
                    }
                }
            }
        }
    }
}

@mixin link-to-solid-btn {
    display: inline-block;
    vertical-align: middle;
    padding: $button-padding;
    -webkit-appearance: none;
    border: 1px solid #0000;
    border-radius: $button-radius;
    transition: $button-transition;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    background-color: $primary-color;
    color: $button-color;
    box-shadow: $button-box-shadow;
    &:hover {
        box-shadow: $button-hover-box-shadow;
    }
}

@mixin link-to-hollow-btn {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: $button-padding;
    -webkit-appearance: none;
    border: 2px solid lighten($primary-color, 20%);
    border-radius: $button-radius;
    transition: $button-transition;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    background-color: transparent;
    color: lighten($primary-color, 5%);
    &:hover {
        background-color: rgba($primary-color, .2);
    }
}
