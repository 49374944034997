@use 'sass:math';
//Colors List

$black			: #000000;
$cyan			: #0073C0;
$magenta		: #EC008C;
$yellow			: #FFBB00;
$light-cyan		: scale-color($cyan, $lightness: 20%);
$light-magenta	: scale-color($magenta, $lightness: 20%);
$neon-yellow	: #FFF200;
$red			: #c50000;
$blue			: #3f46d5;
$dark-gray		: scale-color($black, $lightness: 20%);
$gray			: scale-color($black, $lightness: 50%);
$green			: #74c045;
$light-gray		: scale-color($black, $lightness: 80%);
$orange			: #ee5b2e;


$color-names: "black", "cyan", "magenta", "pink", "yellow", "red", "blue", "gray", "green", "orange";
$color-lists: $black, $cyan, $magenta, $magenta, $yellow, $red, $blue, $gray, $green, $orange;
$color-spot-size: 16px;
$color-spot-pos: math.div($color-spot-size, 4) - .5px;

%spotIndicator{
    color: $dark-gray;
    line-height:1;
    font-weight:bold;
    font-size: math.div($color-spot-size, 2);
    display:inline-block;
    vertical-align:middle;
    position: absolute;
    top: 20%;
}

@for $i from 1 through length($color-names){
    $current-class: nth($color-names, $i);
    .color-spot{
        &.#{$current-class}{
            background: nth($color-lists, $i);
        }
    }
}

.color-spot{
    width: $color-spot-size;
    height: $color-spot-size;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin: rem-calc(0 2);
    vertical-align: middle;
    &.photo{
        background: #efefef !important;
        border: $global-border;
        &:before{
            content: 'P';
            @extend %spotIndicator;
            left: 35.5%;
        }
    }
    &.matte{
        background: #efefef !important;
        border: $global-border;
        &:before{
            content: 'M';
            @extend %spotIndicator;
            left: 25%;
        }
    }
    &.fluorescent {
        &:before{
            content: 'FL';
            @extend %spotIndicator;
            left: 25%;
            color: $white;
        }
    }
    &.multi-colors, &.tri-color{
        $spot-size-half: math.div($color-spot-size, 2);
        $spot-size-quarter: math.div($color-spot-size, 4);
        background-image:
            radial-gradient(circle at $spot-size-quarter $spot-size-quarter, $cyan, $cyan $color-spot-pos, rgba(0, 0, 0, 0) $color-spot-pos),
            radial-gradient(circle at $spot-size-quarter $spot-size-quarter, $magenta, $magenta $color-spot-pos, rgba(0, 0, 0, 0) $color-spot-pos),
            radial-gradient(circle at $spot-size-half $spot-size-quarter, $yellow, $yellow $color-spot-pos, rgba(0, 0, 0, 0) $color-spot-pos);
        background-size: $spot-size-half $spot-size-half, $color-spot-size 10px, $color-spot-size $spot-size-half;
        background-position: $color-spot-pos 0, 0 $color-spot-pos*2, $color-spot-pos $color-spot-pos*2;
        background-repeat: no-repeat;
    }
}
.combo-sets{
    display:inline-block;
    padding: 0 5px;
}

