[type='checkbox'], [type='radio'] {
    @include opacity-rules($value: 0);
    z-index: 1;
    position: relative;
    display: none;
    cursor: pointer;
    @include disable-mouse-outline;
    &:hover {
        + label {
            &:after {
                border: $input-border-focus;
                @include transition($input-transition);
            }
        }
    }
    + label {
        margin: rem-calc(0 0 25 34);
        position: relative;
        font-weight: 400;
        line-height: 1;
        &:before {
            @include transition(transform 0.4s cubic-bezier(0.5, 2, 0.5, 0.75));
            @include scaling((0, 0));
            position: absolute;
            z-index: 1;
            top: 50%;
            width: rem-calc(15);
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: rem-calc(-12.5);
            left: rem-calc(-34);
            width: rem-calc(25);
            height: rem-calc(25);
            background: #fff;
            box-shadow: $input-shadow;
            border: $input-border;
        }
    }

    &:checked + label {
        &:before {
            @include scaling((1, 1));
        }
    }
}

label > [type='checkbox'],
label > [type='radio'] {
    margin-#{$global-right}: 1.25rem;
    margin-bottom: .5rem;
}

[type='radio'] + label:before {
    content: "";
    background: get-color('primary');
    border-radius: 50%;
    margin-top: rem-calc(-7.5);
    height: rem-calc(15);
    left: rem-calc(-29);
}

[type='radio'] + label:after {
    border-radius: 50%;
}

[type='checkbox'] + label:before {
    font-family: $icomoon-font-family;
    content: $cas-i-checkmark;
    color: get-color('primary');
    margin-top: rem-calc(-6.5);
    height: rem-calc(13);
    left: rem-calc(-28);
}

[type='checkbox'] + label:after {
    border-radius: $global-radius;
}
