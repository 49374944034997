.upsell-list{
  border-bottom: 1px solid $light-gray;
  padding: 1rem 0;
  &.last{
    border: 0;
  }
}
.row.upsell-list{
  margin: 0;
}
.see-more{
  margin: 1rem 0 0;
}

@media screen and #{breakpoint(large)} {
  .upsell-list{
    border: 0;
    text-align: center;
  }
}