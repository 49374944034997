// Custom Base styles for CAS
// - - - - - - - - - - - - - - -
// These are custom styles applied to basic HTML tags, including:
//   - Global word spacing
//   - Links
//   - Lists
//   - Blockquotes
//   - Citations
//   - Code

/// Default value for global word spacing
$ci-global-word-spacing: .025em !default;

/// Default value for blockquote
$ci-blockquote-border-radius: rem-calc(20) !default;
$ci-blockquote-font-size: 1rem;
$ci-cite-margin-top: .5em;

/// Default value for the custom list
$ci-list-item-side-margin: .5rem !default;
$ci-list-marker: $medium-gray !default;
$ci-defnlist-margin-top: 1rem !default;

/// Default value for the custom links
$ci-links-line-height: 1.1 !default;
$ci-links-font-weight: 600 !default;

@media print {
    a[href] {
        text-decoration: none;
        &:after {
            content: none !important;
        }
    }
}

//Set word spacing for text
body {
    word-spacing: $ci-global-word-spacing;
}

ul, ol, dl, p, span {
    line-height: $global-lineheight;
}

// Links
a {
    line-height: $ci-links-line-height;
    font-weight: $ci-links-font-weight;
    &:hover {
        text-decoration: underline;
    }
    &:focus {
        outline: 2px dotted;
    }
}

//Lists
ul, ol {
    li {
        margin-top: rem-calc(5);
        margin-bottom: rem-calc(5);
    }
}

ul {
    list-style-type: none;
    li {
        &:before {
            border-radius: 50%;
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-top: -2px;
            margin-left: -$ci-list-item-side-margin *1.5;
            margin-right: $ci-list-item-side-margin *1.5;
            vertical-align: middle;
            background: $ci-list-marker;
        }
    }
}

ol {
    margin: rem-calc(0 36);
    counter-reset: item;
    list-style-type: none;
    li {
        margin: 1rem 0;
        text-indent: rem-calc(-16);
        &:nth-child(-n+9):before {
            padding-left: rem-calc(6);
        }
        &:before {
            counter-increment: item;
            content: counter(item) ".";
            color: $ci-list-marker;
            display: inline-block;
            font-size: $global-font-size;
            text-align: right;
            padding-right: rem-calc(10);
            line-height: 1.2;
            font-weight: 700;
        }
    }
    &.lower-alpha {
        li {
            &:before {
                content: counter(item, lower-alpha) ".";
            }
        }
    }
    ol {
        margin-left: 2.5rem;
        counter-reset: item;
        li {
            &:before {
                content: counters(item, ".") ".";
            }
        }
    }
    ul {
        margin-left: 2.5rem;
        list-style-type: none;
        li {
            margin: 0.5rem 0;
            &:before, &:nth-child(-n+9):before {
                counter-reset: item;
                content: "";
                padding: 0;
            }
        }
    }
}

ul, ol {
    &.no-bullet, &.breadcrumbs, &.pagination, &.tabs {
        list-style-type: none;
        li {
            padding-left: 0;
            text-indent: initial;
            &:before {
                background: none;
                width: 0;
                height: 0;
                margin: 0;
                display: none;
            }
        }
    }
}

dl {
    dt {
        margin-top: $ci-defnlist-margin-top;
    }
}

// Blockquotes
blockquote {
    &.compact-bq {
        padding: 1.5rem;
        &, p {
            font-size: 1rem;
        }
        &:before, &:after {
            width: rem-calc(50);
            height: rem-calc(28.5);
        }
    }
    margin: 1.5rem 0;
    padding: 0 1.25rem;
    position: relative;

    &, p {
        font-size: $ci-blockquote-font-size;
    }
    &:before, &:after {
        font-size: 2rem;
        color: get-color('blue');
        position: absolute;
    }
    &:before {
        top: 0;
        left: 0;
        content: open-quote;
    }
    &:after {
        right: 0;
        bottom: 0;
        content: close-quote;
    }
}

// Citations
cite {
    margin-top: $ci-cite-margin-top;
}

//Code
code {
    border-radius: .25rem;
}

address {
    font-style: initial;
}

.ticket-trigger {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    &.white-link {
        padding: 0;
        margin: 0 auto 0.25rem;
    }
}
