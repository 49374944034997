.loading-mask {
    position: fixed;
    background: rgba(darken($dark-gray, 10%), .9);
    z-index: 999;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    .loader {
        width: 100%;
        position: absolute;
        text-align: center;
        top: 50%;
        margin-top: -12.5vh;
        color: $white;
        font-weight: 600;
        img {
            background: get-color('vanilla');
            border-radius: 50%;
            padding: 1rem;
            max-width: rem-calc(96);
        }
        p {
            margin-top: 1rem;
        }
    }
}
