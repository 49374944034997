@import '../icomoon/variables/variables';

.message {
    margin-top: 1rem;
    position: relative;
    padding: $callout-padding;
    background-color: $cas-note-overlay;
    color: $body-font-color;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid get-color(primary);
    &:before{
        font-family: '#{$icomoon-font-family}' !important;
        content: $cas-i-notification;
        color: get-color(primary);
        margin-right: rem-calc(10);
        display: inline-block;
        vertical-align: middle;
        width: rem-calc(18);
        position: absolute;
        left: 1rem;
    }
    > * {
        flex: 0 0 100%;
        max-width: 100%;
    }
    > :last-child, p:last-of-type {
        margin-bottom: 0;
    }
    &.error {
        background: $cas-alert-overlay;
        padding-left: 3rem;
        border-color: get-color(alert);
        &:before{
            content: url('#{$svg-icons-path}error.svg');
        }
    }
    &.success {
        background: $cas-success-overlay;
        padding-left: 3rem;
        border-color: get-color(success);
        &:before{
            content: url('#{$svg-icons-path}success-g.svg');
        }
    }
}
