.input-group {
    flex-wrap: wrap;
    div.mage-error {
        flex: 0 0 100%;
    }
    &-field {
        position: relative;
        border-radius: $global-radius;
        padding-left: 3.25rem;
        margin-left: -3.25rem;
        z-index: 1;
    }
    &-label {
        position: relative;
        z-index: 3;
    }
    &.inline-label {
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        .input-group-label {
            padding-left: 0.5rem;
        }
        input {
            margin-top: 0;
            padding-left: 2.5rem;
        }
    }
}

@media screen and #{breakpoint(medium)}{
    .input-group {
        &-field {
            padding-top: rem-calc(12);
            padding-bottom: rem-calc(12);
        }
    }
}
