%center-dropmenu {
    right: initial;
    left: 50%;
}

.dropdown.menu {
    margin: 0 auto;
    padding: 0.5rem 0;
    > li {
        padding: 1rem;
        > a {
            color: $dark-gray;
            background: transparent;
            .menu-caret {
                color: $medium-gray;
            }
            &:focus {
                outline: 2px solid transparent;
                background-color: $light-gray;
                box-shadow: 0 0 0 6px $light-gray;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    .is-dropdown-submenu {
        box-shadow: 0 6px 12px rgba($black, 0.18);
        background-color: #f7f7f7;
        border: $global-border;
        border-top: 5px solid get-color(primary);
        &:before {
            content:"";
            position: absolute;
            border-style: solid;
            top: rem-calc(-12);
            border-width: rem-calc(0 7 7 7);
            border-color: transparent transparent get-color(primary) transparent;
        }
    }
}

.nav-sections {
    background-color: get-color(primary);
    .menu {
        justify-content: center;
        li {
            margin: 0 0.15rem;
            a {
                color: $white;
                text-decoration: none;
                margin: 0.5rem 0.75rem;
                &.parent-nav-link {
                    &:after {
                        content:'\25BE';
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }
    .dropdown.menu {
        max-width: map-get($breakpoints, 'large');
        justify-content: space-around;
        > li {
            border-top: 3px solid transparent;
            padding: rem-calc(11 17);
            > a {
                font-size: rem-calc(16);
                font-weight: 600;
                .cas-i-coupon {
                    font-size: 1rem;
                }
                &:hover {
                    color: get-color(primary);
                    .menu-caret {
                        color: get-color(primary);
                    }
                }
            }
        }
        .is-dropdown-submenu {
            padding: 2rem 1.5rem;
            > li {
                margin: 0.5rem;
                vertical-align: top;
                a {
                    padding: 0;
                    display: inline-block;
                    &.acss-link {
                        display: block;
                        margin: 0 auto;
                        padding: 0.5rem;
                        img {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
            &.main-nav-menu {
                min-width: rem-calc(990);
                left: 0;
                right: initial;
                &:before {
                    left: rem-calc(75);
                }
            }
            &.accs-nav {
                @extend %center-dropmenu;
                margin-left: rem-calc(-247.5);
                &:before {
                    left: rem-calc(320);
                }
            }
            .sub-nav-title {
                font-size: rem-calc(16);
                display: block;
                margin-left: 0.5rem;
            }
        }
    }
}

.main-nav-menu {
    padding: 1rem;
    .sub-nav-title {
        font-size: rem-calc(16);
    }
    .menu-content {
        margin-bottom: 1rem;
        border-top: 2px solid $dark-gray;
        margin-top: 0.5rem;
        padding: 0.5rem 0.5rem 0;
        a {
            color: $dark-gray;
            background: transparent;
            font-weight: normal;
        }
        > li {
            display: inline-block;
            width: calc(100%/2 - 0.5rem);
            margin: 0;
        }
        &.featured {
            margin-bottom: 1.5rem;
            a {
                font-weight: 700;
                font-family: $sec-font;
                font-size: rem-calc(16);
            }
        }
    }
}
.accessories-menu {
    .menu-content {
        width: 100%;
    }
}
.mobile-account-menu {
    background: $off-white;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border-top: 5px solid get-color(primary);
    position: relative;
    &:before {
        content: "";
        border-width: rem-calc(0 8 8 8);
        border-style: solid;
        border-color: transparent transparent get-color(primary) transparent;
        position: absolute;
        top: rem-calc(-12);
        right: rem-calc(100);
        color: get-color(primary);
    }
    li{
        padding: 0.5rem 0;
        a {
            font-size: rem-calc(16);
            color: $dark-gray;
        }
    }
}

.top-account-menu {
    &.mobile {
        a {
            color: $dark-gray;
            font-size: rem-calc(16);
        }
    }
    .dropdown.menu {
        align-items: center;
        >li {
            padding: 0 1.25rem;
            border-left: 1px solid $dark-gray;
            &.cart-link, &.help {
                border: 0;
            }
            &.cart-link {
                padding-right: 0;
            }
            &:hover {
                .cas-i-cart {
                    color: get-color(primary);
                }
            }
            > a {
                font-weight: normal;
                &:focus {
                    outline: 2px solid transparent;
                    background-color: $light-gray;
                    box-shadow: 0 0 0 6px $light-gray;
                }
                .cas-i-cart {
                    margin-right: rem-calc(7);
                }
            }
            &.top-menu-link {
                padding-bottom: rem-calc(15);
                box-shadow: none;
                > a {
                    &:hover {
                        color: get-color(secondary);
                    }
                }
            }
        }
        .is-dropdown-submenu {
            &.account-menu{
                top: 2rem;
                left: 0;
                right: initial;
                .account-menu-box {
                    color: $dark-gray;
                    margin: 0.5rem 0;
                }
            }
            &.mini-cart {
                top: 2.5rem;
                right: 0;
                left: initial;
                &:before {
                    left: initial;
                    right: rem-calc(50);
                }
            }
            .register {
                text-align: center;
                margin-bottom: 1.5rem;
            }
            &:before {
                left:  rem-calc(30);
            }
            .top-help {
                a {
                    color: $dark-gray;
                    font-weight: normal;
                }
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .main-nav-menu {
        .menu-content {
            > li {
                width: calc(100%/4 - 0.5rem);
                margin: 0.5rem 0;
                vertical-align: top;
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    .nav-sections {
        background: none;
        .menu {
            li {
                a {
                    color: $dark-gray;
                    margin: 0.5rem 1rem;
                    &.parent-nav-link {
                        &:after {
                            content:'';
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    .main-nav-menu {
        padding: 0;
        .menu-content {
            margin-bottom: 0;
        }
    }
}

