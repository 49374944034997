fieldset {
  margin-bottom: 1rem;
  label {
    line-height: 1;
    font-weight: $global-weight-normal;
    &.display-inline-block {
      margin-right: .5rem;
    }
  }
}

legend {
  font-weight: $global-weight-bold;
  margin-bottom: 1rem;
}

.fieldset{
  @include box-wrapper(transparent, 1rem, none);
  @media screen and #{breakpoint(large)} {
    padding: 2rem;
  }
  border-color: $ui-light-gray;
  border-radius: $global-radius;
  label {
    line-height: 2.2;
    font-weight: $global-weight-bold;
  }
  legend{
    margin-bottom: .5rem;
  }
}
.legend{
  padding: 0 1rem;
  font-size: rem-calc(20);
  font-family: 'Work Sans', sans-serif;
}